import React from "react";
import { Helmet } from "react-helmet";

import Favicon from "../images/favicon.png";

export default function Head() {
  return (
    <Helmet>
      <link rel="icon" href={Favicon} />

      {/* <link
                rel="stylesheet preload prefetch"
                as="style"
                href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700"
                crossorigin="anonymous"
            /> */}

      <link
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css"
        rel="stylesheet preload prefetch"
        as="style"
        crossorigin="anonymous"
      />
      <meta name="description" content="Be present, think about your future" />
      <meta name="keywords" content="decret bacs, energy, agrid, a-grid" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta property="og:site_name" content="Agrid" />
      <meta property="og:title" content="Agrid - Decret BACS" />
      <meta property="og:type" content="website" />

      <link rel="stylesheet" href="/css/style.css" async="async" />

      <title>Agrid - Decret BACS</title>
    </Helmet>
  );
}
